<div class="form-group">
  <label for="CategoryName">Category Name</label>
  <input [(ngModel)]="name" class="form-control" id="CategoryName" placeholder="Enter Name">
</div>
<div class="form-group">
  <label for="ParentCategory">Parent Category</label>
  <select [(ngModel)]="selected_parent" id="ParentCategory" class="form-control">
    <option *ngFor="let c of categories" [ngValue]="c.categoryId">{{c.fullpath}}</option>
  </select>
</div>
<button (click)="saveChanges();" type="submit" class="btn btn-primary">Add</button>
