/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./total-videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./total-videos.component";
import * as i4 from "../../../domain/services/video.service";
var styles_TotalVideosComponent = [i0.styles];
var RenderType_TotalVideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TotalVideosComponent, data: {} });
export { RenderType_TotalVideosComponent as RenderType_TotalVideosComponent };
export function View_TotalVideosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [["class", "text-center mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵted(6, null, ["", " Active Videos"]))], function (_ck, _v) { var currVal_1 = _ck(_v, 5, 0, "/"); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pageTitle; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.totalCount; _ck(_v, 6, 0, currVal_2); }); }
export function View_TotalVideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-total-videos", [], null, null, null, View_TotalVideosComponent_0, RenderType_TotalVideosComponent)), i1.ɵdid(1, 114688, null, 0, i3.TotalVideosComponent, [i2.Router, i4.VideoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TotalVideosComponentNgFactory = i1.ɵccf("app-admin-total-videos", i3.TotalVideosComponent, View_TotalVideosComponent_Host_0, {}, {}, []);
export { TotalVideosComponentNgFactory as TotalVideosComponentNgFactory };
