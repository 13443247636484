import { Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { AddCategoryComponent } from './pages/add-category/add-category.component';
import { AdminArchivesComponent } from './pages/archives/admin.archives.component';
import { AdminCategoriesComponent } from './pages/categories/admin.categories.component';
import { AdminUploadComponent } from './pages/upload/admin.upload.component';
import { PrivateVideosComponent } from './pages/private-videos/private-videos.component';
import { InactiveComponent } from './pages/inactive/inactive.component';
import { VideoCategoryListResolverService } from '../common/resolvers/categories/category-list-resolver/video-category-list-resolver.service';
import { MarketGroupsResolverService } from '../common/resolvers/groups/market-groups-resolver/market-groups-resolver';
import { RoleGroupsResolverService } from '../common/resolvers/groups/role-groups-resolver/role-groups-resolver';
import { FranchiseListResolverService } from '../common/resolvers/categories/franchise-list-resolver/franchise-list-resolver';
import { UserAdminGuard } from '../auth/guards/user.admin.guard';
import { FlaggedVideosComponent } from './pages/flagged-videos/flagged-videos.component';
import { ManageCarouselComponent } from './pages/carousel/manage-carousel.component';
import { QueueComponent } from './pages/queue/queue.component';
import { AdminReportingComponent } from './pages/admin-reporting/admin-reporting.component';
const ɵ0 = { title: 'Manage Carousel' }, ɵ1 = { title: 'Flagged Videos' }, ɵ2 = { title: 'Private Videos' }, ɵ3 = { title: 'Admin Reporting' }, ɵ4 = { title: 'Inactive Videos' }, ɵ5 = { title: 'Archives' }, ɵ6 = { title: 'Categories' }, ɵ7 = { title: 'Categories' }, ɵ8 = { title: 'Add Category' }, ɵ9 = { title: 'Upload Video' }, ɵ10 = { title: 'Admin Queue' };
const adminRoutes = [
    {
        path: '',
        canActivate: [UserAdminGuard],
        component: AdminComponent,
        children: [
            {
                path: 'carousel',
                component: ManageCarouselComponent,
                data: ɵ0,
                canActivate: [UserAdminGuard]
            },
            {
                path: 'flagged',
                component: FlaggedVideosComponent,
                data: ɵ1,
                canActivate: [UserAdminGuard]
            },
            {
                path: 'private-videos',
                component: PrivateVideosComponent,
                canActivate: [UserAdminGuard],
                data: ɵ2
            },
            {
                path: 'admin-reporting',
                component: AdminReportingComponent,
                canActivate: [UserAdminGuard],
                data: ɵ3
            },
            {
                path: 'inactive-videos',
                component: InactiveComponent,
                canActivate: [UserAdminGuard],
                data: ɵ4
            },
            {
                path: 'archives',
                component: AdminArchivesComponent,
                canActivate: [UserAdminGuard],
                data: ɵ5
            },
            {
                path: 'categories/:franchise',
                component: AdminCategoriesComponent,
                canActivate: [UserAdminGuard],
                data: ɵ6
            },
            {
                path: 'categories',
                component: AdminCategoriesComponent,
                canActivate: [UserAdminGuard],
                data: ɵ7
            },
            {
                path: 'add-category/:franchise',
                component: AddCategoryComponent,
                canActivate: [UserAdminGuard],
                data: ɵ8
            },
            {
                path: 'upload',
                component: AdminUploadComponent,
                canActivate: [UserAdminGuard],
                data: ɵ9,
                resolve: {
                    categories: VideoCategoryListResolverService,
                    marketGroups: MarketGroupsResolverService,
                    roleGroups: RoleGroupsResolverService,
                    franchiseCategories: FranchiseListResolverService
                }
            },
            {
                path: 'queue',
                component: QueueComponent,
                canActivate: [UserAdminGuard],
                data: ɵ10
            }
        ]
    }
];
export class AppAdminRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
