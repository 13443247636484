/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./layout/total-videos/total-videos.component.ngfactory";
import * as i3 from "./layout/total-videos/total-videos.component";
import * as i4 from "@angular/router";
import * as i5 from "../domain/services/video.service";
import * as i6 from "./layout/admin-nav/admin-nav.component.ngfactory";
import * as i7 from "./layout/admin-nav/admin-nav.component";
import * as i8 from "./admin.component";
var styles_AdminComponent = [i0.styles];
var RenderType_AdminComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminComponent, data: {} });
export { RenderType_AdminComponent as RenderType_AdminComponent };
export function View_AdminComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "secondary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "page-heading text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "far fa-user-circle text-primary"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0Admin Tools "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-admin-total-videos", [], null, null, null, i2.View_TotalVideosComponent_0, i2.RenderType_TotalVideosComponent)), i1.ɵdid(5, 114688, null, 0, i3.TotalVideosComponent, [i4.Router, i5.VideoService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "container-fluid"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "row no-gutters"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "app-admin-nav", [], null, null, null, i6.View_AdminNavComponent_0, i6.RenderType_AdminNavComponent)), i1.ɵdid(10, 114688, null, 0, i7.AdminNavComponent, [i4.Router], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [["class", "col-md-9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "border bg-white p-sm-1 pt-sm-3 p-md-3 h-100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(14, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 5, 0); _ck(_v, 10, 0); _ck(_v, 14, 0); }, null); }
export function View_AdminComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin", [], null, null, null, View_AdminComponent_0, RenderType_AdminComponent)), i1.ɵdid(1, 114688, null, 0, i8.AdminComponent, [i4.Router, i4.ActivatedRoute, i5.VideoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminComponentNgFactory = i1.ɵccf("app-admin", i8.AdminComponent, View_AdminComponent_Host_0, {}, {}, []);
export { AdminComponentNgFactory as AdminComponentNgFactory };
