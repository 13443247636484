import { EventEmitter } from '@angular/core';
import { isNullOrEmpty } from '../../../common/utilities/object-utilities';
import * as i0 from "@angular/core";
export class XapiTrackingService {
    constructor() {
        this.XapiParameterChangeEvent = new EventEmitter();
    }
    get StarsId() {
        return localStorage.getItem('starsId');
    }
    set StarsId(value) {
        if (!isNullOrEmpty(value)) {
            localStorage.setItem('starsId', value);
            this.XapiParameterChangeEvent.emit({ starsId: value });
        }
    }
    get CourseId() {
        return localStorage.getItem('courseId');
    }
    set CourseId(value) {
        if (!isNullOrEmpty(value) || value === 'null') {
            localStorage.setItem('courseId', value);
            this.XapiParameterChangeEvent.emit({ courseId: value });
        }
    }
    get TempStarsId() {
        return localStorage.getItem('temp.starsId');
    }
    set TempStarsId(value) {
        if (isNullOrEmpty(value) || value === 'null') {
            localStorage.removeItem('temp.starsId');
            return;
        }
        else {
            localStorage.setItem('temp.starsId', value);
        }
        this.XapiParameterChangeEvent.emit({ 'temp.starsId': value });
    }
    get TempCourseId() {
        return localStorage.getItem('temp.courseId');
    }
    set TempCourseId(value) {
        if (isNullOrEmpty(value)) {
            localStorage.removeItem('temp.courseId');
            return;
        }
        else {
            localStorage.setItem('temp.courseId', value);
        }
        this.XapiParameterChangeEvent.emit({ 'temp.courseId': value });
    }
}
XapiTrackingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function XapiTrackingService_Factory() { return new XapiTrackingService(); }, token: XapiTrackingService, providedIn: "root" });
