export enum AdminMenuItems {
  Carousel,
  Categories,
  ResetRatings,
  FlaggedVideos,
  AnalyticsReporting,
  AdminReporting,
  Archives,
  Upload,
  PrivateVideos,
  InactiveVideos,
  AdminQueue
}
