/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./carousel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i4 from "@angular/common";
import * as i5 from "./services/carousel-singleton.service";
import * as i6 from "../../domain/services/carousel.service";
import * as i7 from "./carousel.component";
import * as i8 from "../../common/pipes/safe-html/safe-html.pipe";
import * as i9 from "../../core/user/user.service";
var styles_CarouselComponent = [i0.styles];
var RenderType_CarouselComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CarouselComponent, data: {} });
export { RenderType_CarouselComponent as RenderType_CarouselComponent };
function View_CarouselComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "img-fluid"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], [[2, "cursor-default", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLinkClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.slideImagePath + _v.parent.context.$implicit.backgroundImageUrl); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵinlineInterpolate(1, "carousel-caption ", ((_v.parent.context.$implicit.textPosition === 1) ? "right" : "left"), ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.link === null); _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.getTextHtml(_v.parent.context.$implicit); _ck(_v, 3, 0, currVal_3); }); }
function View_CarouselComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 1, null, View_CarouselComponent_2)), i1.ɵdid(1, 16384, [[1, 4]], 0, i2.NgbSlide, [i1.TemplateRef], null, null)], null, null); }
export function View_CarouselComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "ngb-carousel", [["class", "carousel slide"], ["tabIndex", "0"]], [[4, "display", null]], [[null, "keydown.arrowLeft"], [null, "keydown.arrowRight"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("keydown.arrowLeft" === en)) {
        var pd_0 = ((i1.ɵnov(_v, 2).keyboard && i1.ɵnov(_v, 2).prev(i1.ɵnov(_v, 2).NgbSlideEventSource.ARROW_LEFT)) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.arrowRight" === en)) {
        var pd_1 = ((i1.ɵnov(_v, 2).keyboard && i1.ɵnov(_v, 2).next(i1.ɵnov(_v, 2).NgbSlideEventSource.ARROW_RIGHT)) !== false);
        ad = (pd_1 && ad);
    } if (("mouseenter" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).mouseEnter() !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).mouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i3.View_NgbCarousel_0, i3.RenderType_NgbCarousel)), i1.ɵdid(2, 3325952, [["carousel", 4]], 1, i2.NgbCarousel, [i2.NgbCarouselConfig, i1.PLATFORM_ID, i1.NgZone, i1.ChangeDetectorRef], { showNavigationArrows: [0, "showNavigationArrows"], showNavigationIndicators: [1, "showNavigationIndicators"] }, null), i1.ɵqud(603979776, 1, { slides: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CarouselComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showControls(); var currVal_2 = _co.showControls(); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = _co.slides; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = "block"; _ck(_v, 1, 0, currVal_0); }); }
export function View_CarouselComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-carousel", [], null, [["document", "resetSlides"]], function (_v, en, $event) { var ad = true; if (("document:resetSlides" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).handleResetSlides() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_CarouselComponent_0, RenderType_CarouselComponent)), i1.ɵprd(512, null, i5.CarouselSingletonService, i5.CarouselSingletonService, [i6.CarouselService]), i1.ɵdid(2, 245760, null, 0, i7.CarouselComponent, [i5.CarouselSingletonService, i8.SafeHtmlPipe, i9.UserService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CarouselComponentNgFactory = i1.ɵccf("app-carousel", i7.CarouselComponent, View_CarouselComponent_Host_0, {}, {}, []);
export { CarouselComponentNgFactory as CarouselComponentNgFactory };
