import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/video.service";
export class VideoPlaybackUrlResolverService {
    constructor(videoService) {
        this.videoService = videoService;
    }
    resolve(route, state) {
        return this.videoService.getPlaybackUrl(route.queryParams.v);
    }
}
VideoPlaybackUrlResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoPlaybackUrlResolverService_Factory() { return new VideoPlaybackUrlResolverService(i0.ɵɵinject(i1.VideoService)); }, token: VideoPlaybackUrlResolverService, providedIn: "root" });
