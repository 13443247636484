import { Subject } from 'rxjs/internal/Subject';
import * as i0 from "@angular/core";
export class HeaderSearchService {
    constructor() {
        this.open = false;
        this.subject = new Subject();
    }
    resetSearch() {
        this.open = false;
    }
    setToggleSearch() {
        this.open = !this.open;
        this.subject.next(this.open);
    }
    getToggleSearch() {
        return this.subject.asObservable();
    }
    get isSearchOpen() {
        return this.open;
    }
}
HeaderSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HeaderSearchService_Factory() { return new HeaderSearchService(); }, token: HeaderSearchService, providedIn: "root" });
