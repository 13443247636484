import { map } from 'rxjs/operators';
import { UserRoleEnum } from '../../domain/enums/userroletype.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../core/user/user.service";
import * as i2 from "@angular/router";
export class UserDealerGuard {
    constructor(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    canActivate() {
        return this.userService.user$.pipe(map((user) => {
            if (user && (user.userRoleId === UserRoleEnum.SUPER_ADMIN || user.userRoleId === UserRoleEnum.DEALER_ADMIN || user.userRoleId === UserRoleEnum.DEALER)) {
                return true;
            }
            this.router.navigate(['/access-denied']);
            return false;
        }));
    }
}
UserDealerGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserDealerGuard_Factory() { return new UserDealerGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router)); }, token: UserDealerGuard, providedIn: "root" });
