/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./standalone-embed.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./standalone-embed.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../domain/services/xapi.service";
import * as i5 from "../../../core/xapi/services/xapi.tracking.service";
import * as i6 from "../../../core/user/user.service";
var styles_StandaloneEmbedComponent = [i0.styles];
var RenderType_StandaloneEmbedComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_StandaloneEmbedComponent, data: {} });
export { RenderType_StandaloneEmbedComponent as RenderType_StandaloneEmbedComponent };
export function View_StandaloneEmbedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "embed-responsive embed-responsive-16by9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "embed-responsive-item"], ["id", "embed"]], null, null, null, null, null))], null, null); }
export function View_StandaloneEmbedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-standalone-embed", [], null, null, null, View_StandaloneEmbedComponent_0, RenderType_StandaloneEmbedComponent)), i1.ɵdid(1, 4440064, null, 0, i2.StandaloneEmbedComponent, [i3.Router, i3.ActivatedRoute, i4.XapiService, i5.XapiTrackingService, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StandaloneEmbedComponentNgFactory = i1.ɵccf("app-standalone-embed", i2.StandaloneEmbedComponent, View_StandaloneEmbedComponent_Host_0, {}, {}, []);
export { StandaloneEmbedComponentNgFactory as StandaloneEmbedComponentNgFactory };
