

/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.VideoStatus
 */
export enum VideoStatus {

  All = 0,
  Active = 1,
  Inactive = 2,
}
 


