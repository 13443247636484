<div [@transform] (@transform.start)="focusInput()">
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSearch()"><span aria-hidden="true">&times;</span></button>
  <div class="container">
    <div class="form-group">
      <div class="input-group">
        <input [(ngModel)]="query" #headerSearchInput (keyup.enter)="searchPressed()" type="search" [ngClass]="state === 'open' ? 'visible' : 'hidden'" class="form-control" placeholder="Search" />
        <div class="input-group-append"><i class=" fa fa-2x fa-search" (click)="searchPressed()"></i></div>
      </div>
    </div>
  </div>
</div>
