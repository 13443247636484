/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./admin-reporting.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./admin-reporting.component";
import * as i4 from "@angular/common/http";
var styles_AdminReportingComponent = [i0.styles];
var RenderType_AdminReportingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdminReportingComponent, data: {} });
export { RenderType_AdminReportingComponent as RenderType_AdminReportingComponent };
function View_AdminReportingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-download"]], null, null, null, null, null))], null, null); }
function View_AdminReportingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
function View_AdminReportingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-download"]], null, null, null, null, null))], null, null); }
function View_AdminReportingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-spinner fa-spin"]], null, null, null, null, null))], null, null); }
export function View_AdminReportingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 27, "div", [["class", "table-responsive-sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 26, "table", [["class", "table table-hover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "thead", [["class", "bg-primary text-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Report Name"])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "th", [["class", "text-right"], ["scope", "col"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 20, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Active Video Report"])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "td", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 5, "button", [["class", "btn btn-primary btn-small"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadReport("active") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminReportingComponent_1)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminReportingComponent_2)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" Download Report "])), (_l()(), i1.ɵeld(18, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Inactive/Deleted Video Report"])), (_l()(), i1.ɵeld(21, 0, null, null, 6, "td", [["class", "text-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "button", [["class", "btn btn-primary btn-small"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadReport("inactive-deleted") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminReportingComponent_3)), i1.ɵdid(24, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdminReportingComponent_4)), i1.ɵdid(26, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, [" Download Report "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.isLoading["active"]; _ck(_v, 14, 0, currVal_1); var currVal_2 = _co.isLoading["active"]; _ck(_v, 16, 0, currVal_2); var currVal_4 = !_co.isLoading["inactive-deleted"]; _ck(_v, 24, 0, currVal_4); var currVal_5 = _co.isLoading["inactive-deleted"]; _ck(_v, 26, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading["active"]; _ck(_v, 12, 0, currVal_0); var currVal_3 = _co.isLoading["inactive-deleted"]; _ck(_v, 22, 0, currVal_3); }); }
export function View_AdminReportingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-admin-reporting", [], null, null, null, View_AdminReportingComponent_0, RenderType_AdminReportingComponent)), i1.ɵdid(1, 114688, null, 0, i3.AdminReportingComponent, [i4.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AdminReportingComponentNgFactory = i1.ɵccf("app-admin-reporting", i3.AdminReportingComponent, View_AdminReportingComponent_Host_0, {}, {}, []);
export { AdminReportingComponentNgFactory as AdminReportingComponentNgFactory };
