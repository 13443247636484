export enum UserTypeEnum {
  Supplier = 1,

  Employee = 2,

  Nonovvm = 2,

  Other = 3,

  Dealer = 5,
}
