import { map, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../auth.service";
import * as i2 from "@angular/router";
import * as i3 from "@auth0/angular-jwt/src/jwthelper.service";
/**
 * @description UserAuthGuard restricts access to routes until the user is authenticated.
 */
export class UserAuthGuard {
    constructor(authService, router, jwtHelperService) {
        this.authService = authService;
        this.router = router;
        this.jwtHelperService = jwtHelperService;
    }
    canActivate(next, state) {
        return this.authService.isAuthenticated$.pipe(take(1), map(isAuthenticated => {
            if (localStorage.getItem("token") != null) {
                let isExpired = this.jwtHelperService.isTokenExpired(localStorage.getItem("token"));
                if (isExpired == true) {
                    this.authService.logout();
                    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url.replace("?", "%%%%%").replace(/&/g, '%%%%%%') } });
                }
            }
            if (isAuthenticated) {
                return true;
            }
            else {
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url.replace("?", "%%%%%").replace(/&/g, '%%%%%%') } });
                return false;
            }
        }));
    }
}
UserAuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAuthGuard_Factory() { return new UserAuthGuard(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.JwtHelperService)); }, token: UserAuthGuard, providedIn: "root" });
