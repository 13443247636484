import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class LoaderService {
    constructor() {
        this._loaderVisibility = new BehaviorSubject(false);
        this.loaderVisibility = this._loaderVisibility.asObservable();
        this.loading$ = new BehaviorSubject(false);
    }
    show() {
        this._loaderVisibility.next(true);
    }
    hide() {
        this._loaderVisibility.next(false);
    }
}
LoaderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoaderService_Factory() { return new LoaderService(); }, token: LoaderService, providedIn: "root" });
