/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/events-list/events-list.component.ngfactory";
import * as i3 from "../../../common/events-list/events-list.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../domain/services/events.service";
import * as i6 from "../../../common/calendar/services/calendar-event.service";
import * as i7 from "../../../common/pipes/strip-html/strip-html.pipe";
import * as i8 from "./live.component";
var styles_LiveComponent = [i0.styles];
var RenderType_LiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LiveComponent, data: {} });
export { RenderType_LiveComponent as RenderType_LiveComponent };
export function View_LiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-events-list", [], null, null, null, i2.View_EventsListComponent_0, i2.RenderType_EventsListComponent)), i1.ɵdid(2, 114688, null, 0, i3.EventsListComponent, [i4.ActivatedRoute, i5.EventsService, i6.CalendarEventService, i7.StripHtmlPipe], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_LiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-live", [], null, null, null, View_LiveComponent_0, RenderType_LiveComponent)), i1.ɵdid(1, 114688, null, 0, i8.LiveComponent, [i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveComponentNgFactory = i1.ɵccf("app-live", i8.LiveComponent, View_LiveComponent_Host_0, {}, {}, []);
export { LiveComponentNgFactory as LiveComponentNgFactory };
