import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/groups.service";
export class RoleGroupsResolverService {
    constructor(groupsService) {
        this.groupsService = groupsService;
    }
    resolve(route, state) {
        return this.groupsService.roleGroups().pipe(response => response);
    }
}
RoleGroupsResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleGroupsResolverService_Factory() { return new RoleGroupsResolverService(i0.ɵɵinject(i1.GroupsService)); }, token: RoleGroupsResolverService, providedIn: "root" });
