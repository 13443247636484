

/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.SearchFieldType
 */
export enum SearchFieldType {

  All = 0,
  Title = 1,
  Tags = 2,
  Categories = 3,
  Uploader = 4
}
 


