/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./load-more.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./directives/load-more.directive";
import * as i3 from "./load-more.component";
var styles_LoadMoreComponent = [i0.styles];
var RenderType_LoadMoreComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadMoreComponent, data: {} });
export { RenderType_LoadMoreComponent as RenderType_LoadMoreComponent };
export function View_LoadMoreComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "app-load-more-template", [], null, [[null, "loadMoreEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadMoreEvent" === en)) {
        var pd_0 = (_co.loadMoreEvent.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, [["loadMoreDirective", 4]], 0, i2.LoadMoreDirective, [], null, { loadMoreEvent: "loadMoreEvent" }), (_l()(), i1.ɵeld(2, 0, null, null, 2, "section", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).loadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["View More"]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LoadMoreComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load-more", [], null, null, null, View_LoadMoreComponent_0, RenderType_LoadMoreComponent)), i1.ɵdid(1, 49152, null, 0, i3.LoadMoreComponent, [], null, null)], null, null); }
var LoadMoreComponentNgFactory = i1.ɵccf("app-load-more", i3.LoadMoreComponent, View_LoadMoreComponent_Host_0, { id: "id" }, { loadMoreEvent: "loadMoreEvent" }, []);
export { LoadMoreComponentNgFactory as LoadMoreComponentNgFactory };
