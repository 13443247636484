/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./private-videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/videos-list/videos-list.component.ngfactory";
import * as i3 from "../../../common/videos-list/videos-list.component";
import * as i4 from "./private-videos.component";
import * as i5 from "../../../domain/services/video.service";
var styles_PrivateVideosComponent = [i0.styles];
var RenderType_PrivateVideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrivateVideosComponent, data: {} });
export { RenderType_PrivateVideosComponent as RenderType_PrivateVideosComponent };
export function View_PrivateVideosComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-videos-list", [], null, [[null, "moreEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("moreEvent" === en)) {
        var pd_0 = (_co.moreVideos() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_VideosListComponent_0, i2.RenderType_VideosListComponent)), i1.ɵdid(1, 49152, null, 0, i3.VideosListComponent, [], { videos: [0, "videos"], totalVideos: [1, "totalVideos"], scrollId: [2, "scrollId"] }, { moreEvent: "moreEvent" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videos; var currVal_1 = _co.totalVideos; var currVal_2 = _co.scrollId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PrivateVideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-private-videos", [], null, null, null, View_PrivateVideosComponent_0, RenderType_PrivateVideosComponent)), i1.ɵdid(1, 114688, null, 0, i4.PrivateVideosComponent, [i5.VideoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrivateVideosComponentNgFactory = i1.ɵccf("app-private-videos", i4.PrivateVideosComponent, View_PrivateVideosComponent_Host_0, {}, {}, []);
export { PrivateVideosComponentNgFactory as PrivateVideosComponentNgFactory };
