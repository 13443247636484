/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flagged-videos.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../../../common/load-more/load-more.component.ngfactory";
import * as i5 from "../../../common/load-more/load-more.component";
import * as i6 from "../../../common/pipes/name-normalize/name-normalize.pipe";
import * as i7 from "./flagged-videos.component";
import * as i8 from "../../../domain/services/video.service";
var styles_FlaggedVideosComponent = [i0.styles];
var RenderType_FlaggedVideosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlaggedVideosComponent, data: {} });
export { RenderType_FlaggedVideosComponent as RenderType_FlaggedVideosComponent };
function View_FlaggedVideosComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "h6", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Reported by "])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 1), (_l()(), i1.ɵted(-1, null, [" at "])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵppd(9, 2), (_l()(), i1.ɵeld(10, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comment: "])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 1), _v.context.$implicit.name)); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i1.ɵnov(_v.parent.parent, 0), _v.context.$implicit.createdOn, "MM/dd/yyyy h:mma")); _ck(_v, 8, 0, currVal_1); var currVal_2 = _v.context.$implicit.comment; _ck(_v, 13, 0, currVal_2); }); }
function View_FlaggedVideosComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "li", [["class", "list-group-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 22, "div", [["class", "flex-fill order-2 ml-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "h6", [["class", "d-inline-block text-uppercase"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(5, { v: 0 }), i1.ɵpad(6, 1), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), i1.ɵppd(11, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlaggedVideosComponent_2)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(17, { v: 0 }), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, ["Watch"])), (_l()(), i1.ɵeld(20, 0, null, null, 1, "div", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeVideo(_v.context.$implicit.video.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(22, 0, null, null, 1, "div", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.unflagVideo(_v.context.$implicit.video.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Unflag"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 5, 0, _v.context.$implicit.video.id); var currVal_1 = _ck(_v, 6, 0, "/watch"); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.reports; _ck(_v, 13, 0, currVal_4); var currVal_5 = _ck(_v, 17, 0, _v.context.$implicit.video.id); var currVal_6 = _ck(_v, 18, 0, "/watch"); _ck(_v, 16, 0, currVal_5, currVal_6); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.video.title; _ck(_v, 7, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.video.whenUploaded, "mediumDate")); _ck(_v, 10, 0, currVal_3); }); }
function View_FlaggedVideosComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load-more", [], null, [[null, "loadMoreEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadMoreEvent" === en)) {
        var pd_0 = (_co.loadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_LoadMoreComponent_0, i4.RenderType_LoadMoreComponent)), i1.ɵdid(1, 49152, null, 0, i5.LoadMoreComponent, [], null, { loadMoreEvent: "loadMoreEvent" })], null, null); }
export function View_FlaggedVideosComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i3.DatePipe, [i1.LOCALE_ID]), i1.ɵpid(0, i6.NameNormalizePipe, []), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "list-group list-group-flush"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlaggedVideosComponent_1)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FlaggedVideosComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videos; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.allowNextButton; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_FlaggedVideosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flagged-videos", [], null, null, null, View_FlaggedVideosComponent_0, RenderType_FlaggedVideosComponent)), i1.ɵdid(1, 114688, null, 0, i7.FlaggedVideosComponent, [i8.VideoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlaggedVideosComponentNgFactory = i1.ɵccf("app-flagged-videos", i7.FlaggedVideosComponent, View_FlaggedVideosComponent_Host_0, {}, {}, []);
export { FlaggedVideosComponentNgFactory as FlaggedVideosComponentNgFactory };
