<div class="table-responsive-sm">
  <table class="table table-hover">
    <thead class="bg-primary text-white">
      <tr>
        <th scope="col" class="cursor-pointer" (click)="setSortField('title')">
          Title&nbsp;<i *ngIf="sortField === 'title'" [ngClass]="reverseSort ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
        </th>
        <th scope="col" class="cursor-pointer" (click)="setSortField('whenUploaded')">
          Upload Date&nbsp;<i *ngIf="sortField === 'whenUploaded'" [ngClass]="reverseSort ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
        </th>
        <th scope="col" class="cursor-pointer" (click)="setSortField('uploadedBy')">
          Uploaded By&nbsp;<i *ngIf="sortField === 'uploadedBy'" [ngClass]="reverseSort ? 'fas fa-caret-up' : 'fas fa-caret-down'"></i>
        </th>
        <th scope="col"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let v of (videos | sortBy: sortField : reverseSort : true); let i = index">
        <th scope="row">{{v.title}}</th>
        <td>{{v.whenUploaded | date:"MMM d, y, h:mm a"}}</td>
        <td>{{v.uploadedBy | nameNormalize}}</td>
        <td class="text-right">
          <div ngbDropdown #dropdown="ngbDropdown" [autoClose]="'outside'" placement="bottom-right">
            <div class="btn btn-light text-primary p-2" ngbDropdownToggle>
              <i class="fas fa-ellipsis-v"></i>
            </div>
            <div ngbDropdownMenu>
              <button class="dropdown-item cursor-pointer" [routerLink]="['/watch']" [queryParams]="{'v': v.id}">Watch</button>
              <button class="dropdown-item cursor-pointer" (click)="activateVideo(v.id)">Activate</button>
              <button class="dropdown-item cursor-pointer text-danger" placement="left" [autoClose]="'outside'" container="body" [ngbPopover]="confirmDelteContent" popoverTitle="Delete Video">Delete</button>
            </div>
          </div>
          <ng-template #confirmDelteContent>
            <p>Are you sure you would like to delete {{v.title}}</p>
            <button class="btn btn-small btn-danger" (click)="removeVideo(v.id)"><i class="fas fa-trash-alt"></i>
              Delete
            </button>
          </ng-template>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<app-load-more *ngIf="allowNextButton()" (loadMoreEvent)="loadMore()"></app-load-more>
