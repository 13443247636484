

/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.VideoType
 */
export enum VideoType {

  All = 0,
  Live = 1,
  Vod = 2,
}
 


