import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/video.service";
import * as i2 from "../../../../core/user/user.service";
export class VideoDetailsResolverService {
    constructor(videoService, userService) {
        this.videoService = videoService;
        this.userService = userService;
    }
    resolve(route, state) {
        const user = this.userService.getUser();
        const userId = user ? user.userName : 'null';
        const model = {
            id: route.queryParams.v || route.queryParams.video_key,
            userName: userId,
        };
        return this.videoService.detailsFlagged(model);
    }
}
VideoDetailsResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoDetailsResolverService_Factory() { return new VideoDetailsResolverService(i0.ɵɵinject(i1.VideoService), i0.ɵɵinject(i2.UserService)); }, token: VideoDetailsResolverService, providedIn: "root" });
