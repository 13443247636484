

/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.FranchiseType
 */
export enum FranchiseType {

  Ford = 0,
  Lincoln = 1,
  Both = 2,
}
 


