/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-nav.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ng-bootstrap/ng-bootstrap";
import * as i5 from "./side-nav.component";
import * as i6 from "./services/side-nav.service";
import * as i7 from "../../domain/services/categories.service";
import * as i8 from "../../core/user/user.service";
var styles_SideNavComponent = [i0.styles];
var RenderType_SideNavComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideNavComponent, data: { "animation": [{ type: 7, name: "transform", definitions: [{ type: 0, name: "open", styles: { type: 6, styles: { transform: "translate3d(0, 0, 0)", visibility: "visible" }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { visibility: "hidden" }, offset: null }, options: undefined }, { type: 1, expr: "void => open", animation: { type: 4, styles: null, timings: "250ms ease-in" }, options: null }, { type: 1, expr: "open => void", animation: { type: 4, styles: null, timings: "250ms ease-out" }, options: null }], options: {} }] } });
export { RenderType_SideNavComponent as RenderType_SideNavComponent };
function View_SideNavComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-chevron-down"]], null, null, null, null, null))], null, null); }
function View_SideNavComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "fas fa-chevron-up"]], null, null, null, null, null))], null, null); }
function View_SideNavComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "a", [["class", "list-group-item list-group-item-action"]], [[8, "id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(2, { c: 0 }), i1.ɵpad(3, 1), (_l()(), i1.ɵted(4, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _ck(_v, 2, 0, _v.context.$implicit.categoryId); var currVal_4 = _ck(_v, 3, 0, "/category"); _ck(_v, 1, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.fullpath; var currVal_1 = i1.ɵnov(_v, 1).target; var currVal_2 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_5); }); }
function View_SideNavComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "a", [["class", "list-group-item list-group-item-action"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "vid-categories-list ignore-click"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.isCollapsed = !_co.isCollapsed) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "far fa-folder-open fa-2x align-middle mr-2 fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Video Categories\u00A0 "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [], [[2, "collapse", null], [2, "show", null]], null, null, null, null)), i1.ɵdid(11, 16384, null, 0, i4.NgbCollapse, [], { collapsed: [0, "collapsed"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_4)), i1.ɵdid(13, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "vid-categories-list ignore-click"; var currVal_1 = (!_co.isCollapsed ? "expanded" : ""); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.isCollapsed; _ck(_v, 7, 0, currVal_2); var currVal_3 = !_co.isCollapsed; _ck(_v, 9, 0, currVal_3); var currVal_6 = _co.isCollapsed; _ck(_v, 11, 0, currVal_6); var currVal_7 = _co.categories; _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var currVal_4 = true; var currVal_5 = !i1.ɵnov(_v, 11).collapsed; _ck(_v, 10, 0, currVal_4, currVal_5); }); }
function View_SideNavComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-action"], ["routerLink", "/upload"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-upload fa-2x align-middle mr-2 fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Upload a Video "]))], function (_ck, _v) { var currVal_2 = "/upload"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideNavComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-action"], ["routerLink", "/manage"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-cogs fa-2x align-middle mr-2 fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Videos "]))], function (_ck, _v) { var currVal_2 = "/manage"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_SideNavComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-action"], ["routerLink", "/admin"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-user fa-2x align-middle mr-2 fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Admin Tools "]))], function (_ck, _v) { var currVal_2 = "/admin"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).target; var currVal_1 = i1.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_SideNavComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 20, "aside", [], [[24, "@transform", 0]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 19, "nav", [["class", "list-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "a", [["class", "list-group-item list-group-item-action"], ["routerLink", "/search"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["Search"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-action"], ["routerLink", "/live"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fab fa-youtube-square fa-2x align-middle mr-2 fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["LIVE "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_1)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_5)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_6)), i1.ɵdid(14, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(15, 0, null, null, 3, "a", [["class", "list-group-item list-group-item-action"], ["routerLink", "/faq"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", "fas fa-info-circle fa-2x align-middle mr-2 fa-fw"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FAQS "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideNavComponent_7)), i1.ɵdid(20, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "/search"; _ck(_v, 3, 0, currVal_3); var currVal_6 = "/live"; _ck(_v, 6, 0, currVal_6); var currVal_7 = (_co.categories.length > 0); _ck(_v, 10, 0, currVal_7); var currVal_8 = _co.showEmployeeDealerItems; _ck(_v, 12, 0, currVal_8); var currVal_9 = _co.showEmployeeDealerItems; _ck(_v, 14, 0, currVal_9); var currVal_12 = "/faq"; _ck(_v, 16, 0, currVal_12); var currVal_13 = _co.showAdminItems; _ck(_v, 20, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.toggleMenu; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3).target; var currVal_2 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 6).target; var currVal_5 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_4, currVal_5); var currVal_10 = i1.ɵnov(_v, 16).target; var currVal_11 = i1.ɵnov(_v, 16).href; _ck(_v, 15, 0, currVal_10, currVal_11); }); }
export function View_SideNavComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidenav", [], [[40, "@transform", 0]], null, null, View_SideNavComponent_0, RenderType_SideNavComponent)), i1.ɵdid(1, 4308992, null, 0, i5.SideNavComponent, [i6.SideNavService, i7.CategoriesService, i2.Router, i8.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toggleState; _ck(_v, 0, 0, currVal_0); }); }
var SideNavComponentNgFactory = i1.ɵccf("app-sidenav", i5.SideNavComponent, View_SideNavComponent_Host_0, {}, {}, []);
export { SideNavComponentNgFactory as SideNavComponentNgFactory };
