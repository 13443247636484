

/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.SortDirectionType
 */
export enum SortDirectionType {

  Asc = 0,
  Desc = 1,
}
 


