<section class="secondary">
  <h3 class="page-heading text-uppercase">
    <i class="far fa-user-circle text-primary"></i>&nbsp;Admin Tools
  </h3>
</section>
<app-admin-total-videos></app-admin-total-videos>
<div class="container-fluid">
  <div class="row no-gutters">
    <div class="col-md-3">
      <app-admin-nav></app-admin-nav>
    </div>
    <div class="col-md-9">
      <div class="border bg-white p-sm-1 pt-sm-3 p-md-3 h-100">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
