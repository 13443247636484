import { environment } from '../../../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../domain/services/carousel.service";
export class CarouselSingletonService {
    constructor(_carouselService) {
        this._carouselService = _carouselService;
    }
    get slides() {
        return this._slides || [];
    }
    set slides(slidesParameter) {
        this._slides = slidesParameter;
    }
    resetSlides() {
        this.getSlides().subscribe(response => {
            this.slides = response;
        });
    }
    getSlides() {
        return this._carouselService.getActive(environment.franchise);
    }
}
CarouselSingletonService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CarouselSingletonService_Factory() { return new CarouselSingletonService(i0.ɵɵinject(i1.CarouselService)); }, token: CarouselSingletonService, providedIn: "root" });
