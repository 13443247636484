/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./edit.component";
var styles_EditComponent = [i0.styles];
var RenderType_EditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EditComponent, data: {} });
export { RenderType_EditComponent as RenderType_EditComponent };
export function View_EditComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" edit works!\n"]))], null, null); }
export function View_EditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-edit", [], null, null, null, View_EditComponent_0, RenderType_EditComponent)), i1.ɵdid(1, 114688, null, 0, i2.EditComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditComponentNgFactory = i1.ɵccf("app-edit", i2.EditComponent, View_EditComponent_Host_0, {}, {}, []);
export { EditComponentNgFactory as EditComponentNgFactory };
