/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./header-search.component";
import * as i5 from "./services/header-search.service";
import * as i6 from "@angular/router";
var styles_HeaderSearchComponent = [i0.styles];
var RenderType_HeaderSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderSearchComponent, data: { "animation": [{ type: 7, name: "transform", definitions: [{ type: 0, name: "open", styles: { type: 6, styles: { transform: "translate3d(0, 0, 0)", visibility: "visible" }, offset: null }, options: undefined }, { type: 0, name: "void", styles: { type: 6, styles: { visibility: "hidden" }, offset: null }, options: undefined }, { type: 1, expr: "void <=> open", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }] } });
export { RenderType_HeaderSearchComponent as RenderType_HeaderSearchComponent };
export function View_HeaderSearchComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { headerSearchInput: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [], [[24, "@transform", 0]], [[null, "@transform.start"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@transform.start" === en)) {
        var pd_0 = (_co.focusInput() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, [[1, 0], ["headerSearchInput", 1]], null, 7, "input", [["class", "form-control"], ["placeholder", "Search"], ["type", "search"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keyup.enter"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.query = $event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        var pd_5 = (_co.searchPressed() !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(11, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(13, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(15, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 0, "i", [["class", " fa fa-2x fa-search"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.searchPressed() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_8 = "form-control"; var currVal_9 = ((_co.state === "open") ? "visible" : "hidden"); _ck(_v, 10, 0, currVal_8, currVal_9); var currVal_10 = _co.query; _ck(_v, 13, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 15).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 15).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 15).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 15).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 15).ngClassValid; var currVal_6 = i1.ɵnov(_v, 15).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 15).ngClassPending; _ck(_v, 8, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_HeaderSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header-search", [], [[40, "@transform", 0]], null, null, View_HeaderSearchComponent_0, RenderType_HeaderSearchComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderSearchComponent, [i5.HeaderSearchService, i6.Router, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).toggleState; _ck(_v, 0, 0, currVal_0); }); }
var HeaderSearchComponentNgFactory = i1.ɵccf("app-header-search", i4.HeaderSearchComponent, View_HeaderSearchComponent_Host_0, {}, {}, []);
export { HeaderSearchComponentNgFactory as HeaderSearchComponentNgFactory };
