import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/categories.service";
export class VideoCategoryListResolverService {
    constructor(categoriesService) {
        this.categoriesService = categoriesService;
    }
    resolve(route, state) {
        return this.categoriesService.get().pipe(response => response);
    }
}
VideoCategoryListResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoCategoryListResolverService_Factory() { return new VideoCategoryListResolverService(i0.ɵɵinject(i1.CategoriesService)); }, token: VideoCategoryListResolverService, providedIn: "root" });
