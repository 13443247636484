/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./standalone.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./standalone.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../core/xapi/services/xapi.tracking.service";
import * as i5 from "../../../common/pipes/safe-embed/safe-embed.pipe";
import * as i6 from "../../../domain/services/xapi.service";
import * as i7 from "../../../core/user/user.service";
var styles_StandaloneComponent = [i0.styles];
var RenderType_StandaloneComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StandaloneComponent, data: {} });
export { RenderType_StandaloneComponent as RenderType_StandaloneComponent };
export function View_StandaloneComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "html", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "body", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "embed-responsive embed-responsive-16by9"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "embed-responsive-item"], ["id", "embed"]], null, null, null, null, null))], null, null); }
export function View_StandaloneComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-standalone", [], null, null, null, View_StandaloneComponent_0, RenderType_StandaloneComponent)), i1.ɵdid(1, 4308992, null, 0, i2.StandaloneComponent, [i3.Router, i3.ActivatedRoute, i4.XapiTrackingService, i5.SafeEmbedPipe, i6.XapiService, i7.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StandaloneComponentNgFactory = i1.ɵccf("app-standalone", i2.StandaloneComponent, View_StandaloneComponent_Host_0, {}, {}, []);
export { StandaloneComponentNgFactory as StandaloneComponentNgFactory };
