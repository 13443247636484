import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/video.service";
export class StandaloneVideoIdResolverService {
    constructor(videoService) {
        this.videoService = videoService;
    }
    resolve(route, state) {
        return this.videoService.getId(route.queryParams.video_key);
    }
}
StandaloneVideoIdResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StandaloneVideoIdResolverService_Factory() { return new StandaloneVideoIdResolverService(i0.ɵɵinject(i1.VideoService)); }, token: StandaloneVideoIdResolverService, providedIn: "root" });
