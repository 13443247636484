/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./manage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../../common/load-more/load-more.component.ngfactory";
import * as i4 from "../../../common/load-more/load-more.component";
import * as i5 from "@angular/common";
import * as i6 from "./manage.component";
import * as i7 from "../../../domain/services/video.service";
import * as i8 from "../../../core/user/user.service";
var styles_ManageComponent = [i0.styles];
var RenderType_ManageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ManageComponent, data: {} });
export { RenderType_ManageComponent as RenderType_ManageComponent };
function View_ManageComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "li", [["class", "list-group-item d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "order-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "img", [["class", "img-fluid float-left"]], [[8, "src", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(4, { "v": 0 }), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 20, "div", [["class", "flex-fill order-2 ml-2 ml-md-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "h6", [["class", "d-inline-block text-uppercase"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""])), i1.ɵppd(13, 2), (_l()(), i1.ɵeld(14, 0, null, null, 12, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(16, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(17, { "v": 0 }), i1.ɵpad(18, 1), (_l()(), i1.ɵted(-1, null, ["Watch"])), (_l()(), i1.ɵeld(20, 0, null, null, 4, "div", [["class", "btn btn-sm btn-primary my-2 my-sm-0 mx-sm-2"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(21, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpod(22, { "v": 0 }), i1.ɵpad(23, 1), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(25, 0, null, null, 1, "div", [["class", "btn btn-sm btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeVideo(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"]))], function (_ck, _v) { var currVal_1 = _ck(_v, 4, 0, _v.context.$implicit.id); var currVal_2 = _ck(_v, 5, 0, "/watch"); _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_5 = _ck(_v, 17, 0, _v.context.$implicit.id); var currVal_6 = _ck(_v, 18, 0, "/watch"); _ck(_v, 16, 0, currVal_5, currVal_6); var currVal_7 = _ck(_v, 22, 0, _v.context.$implicit.id); var currVal_8 = _ck(_v, 23, 0, "/edit-video"); _ck(_v, 21, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.thumbnailUrl; _ck(_v, 2, 0, currVal_0); var currVal_3 = _v.context.$implicit.title; _ck(_v, 9, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.whenUploaded, "mediumDate")); _ck(_v, 12, 0, currVal_4); }); }
function View_ManageComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-load-more", [], null, [[null, "loadMoreEvent"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loadMoreEvent" === en)) {
        var pd_0 = (_co.loadMore() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_LoadMoreComponent_0, i3.RenderType_LoadMoreComponent)), i1.ɵdid(1, 49152, null, 0, i4.LoadMoreComponent, [], null, { loadMoreEvent: "loadMoreEvent" })], null, null); }
export function View_ManageComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "section", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "page-heading"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Manage Videos"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "ul", [["class", "list-group list-group-flush "]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageComponent_1)), i1.ɵdid(7, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ManageComponent_2)), i1.ɵdid(9, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.videos; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.allowNextButton(); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ManageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-manage", [], null, null, null, View_ManageComponent_0, RenderType_ManageComponent)), i1.ɵdid(1, 245760, null, 0, i6.ManageComponent, [i7.VideoService, i8.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ManageComponentNgFactory = i1.ɵccf("app-manage", i6.ManageComponent, View_ManageComponent_Host_0, {}, {}, []);
export { ManageComponentNgFactory as ManageComponentNgFactory };
