import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/video.service";
export class VideoDetailsForEditResolverService {
    constructor(videoService) {
        this.videoService = videoService;
    }
    resolve(route, state) {
        return this.videoService.detailsForEdit(route.queryParams.v).pipe(response => response);
    }
}
VideoDetailsForEditResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoDetailsForEditResolverService_Factory() { return new VideoDetailsForEditResolverService(i0.ɵɵinject(i1.VideoService)); }, token: VideoDetailsForEditResolverService, providedIn: "root" });
