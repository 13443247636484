import { map } from 'rxjs/operators';
import { UserRoleEnum } from '../../domain/enums/userroletype.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../core/user/user.service";
import * as i2 from "@angular/router";
export class UserAdminGuard {
    constructor(userService, router) {
        this.userService = userService;
        this.router = router;
    }
    canActivate() {
        return this.userService.users().pipe(map((user) => {
            if (user && user.userRoleId === UserRoleEnum.SUPER_ADMIN) {
                return true;
            }
            this.router.navigate(['/access-denied']);
            return false;
        }));
    }
}
UserAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserAdminGuard_Factory() { return new UserAdminGuard(i0.ɵɵinject(i1.UserService), i0.ɵɵinject(i2.Router)); }, token: UserAdminGuard, providedIn: "root" });
