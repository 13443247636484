import { SortDirectionType } from '../../../../domain/enums/sortdirectiontype.enum';
import { SortFieldType } from '../../../../domain/enums/sortfieldtype.enum';
import { VideoStatus } from '../../../../domain/enums/videostatus.enum';
import { VideoType } from '../../../../domain/enums/videotype.enum';
import { SearchFieldType } from '../../../../domain/enums/searchfieldtype.enum';
import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/video.service";
export class VideoListResolverService {
    constructor(videoService) {
        this.videoService = videoService;
    }
    resolve(route, state) {
        const searchModel = {
            type: VideoType.All,
            categories: [route.queryParams.c],
            uploaders: [],
            uploaderIds: [],
            status: VideoStatus.Active,
            query: route.queryParams.q || '',
            count: route.data.count || 6,
            titleOnly: false,
            scrollId: null,
            sortField: this.getSortField(route.data.sort),
            sortDirection: route.data.d === 'asc' ? SortDirectionType.Asc : SortDirectionType.Desc,
            searchField: SearchFieldType.All,
            exactMatch: false
        };
        return this.videoService.search(searchModel).pipe(response => response);
    }
    getSortField(param) {
        if (param) {
            switch (param.toLowerCase()) {
                case 'whenuploaded':
                    return SortFieldType.WhenUploaded;
                case 'uploadername':
                    return SortFieldType.UploaderName;
                case 'duration':
                    return SortFieldType.Duration;
                case 'score':
                    return SortFieldType._Score;
                case 'title':
                    return SortFieldType.Title;
                default:
                    return SortFieldType.Title;
            }
        }
        return SortFieldType.Title;
    }
}
VideoListResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoListResolverService_Factory() { return new VideoListResolverService(i0.ɵɵinject(i1.VideoService)); }, token: VideoListResolverService, providedIn: "root" });
