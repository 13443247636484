/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.SortFieldType
 */
export enum SortFieldType {
  Title = 0,
  WhenUploaded = 1,
  UploaderName = 2,
  Duration = 3,
  _Score = 4
}




