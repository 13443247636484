/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "./faq.component";
import * as i6 from "../../domain/services/faq.service";
import * as i7 from "../../core/user/user.service";
var styles_FaqComponent = [i0.styles];
var RenderType_FaqComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FaqComponent, data: {} });
export { RenderType_FaqComponent as RenderType_FaqComponent };
function View_FaqComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.name; _ck(_v, 1, 0, currVal_0); }); }
function View_FaqComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "p", [["class", "text-dark"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.answer; _ck(_v, 0, 0, currVal_0); }); }
function View_FaqComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "ngb-accordion", [["class", "border-0 accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], [[null, "panelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("panelChange" === en)) {
        var pd_0 = (_co.updateTracking($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_NgbAccordion_0, i2.RenderType_NgbAccordion)), i1.ɵdid(1, 2146304, [["acc", 4]], 1, i3.NgbAccordion, [i3.NgbAccordionConfig], null, { panelChange: "panelChange" }), i1.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "ngb-panel", [["class", "card"]], null, null, null, null, null)), i1.ɵdid(4, 2113536, [[1, 4]], 3, i3.NgbPanel, [], { id: [0, "id"], title: [1, "title"] }, null), i1.ɵqud(603979776, 2, { titleTpls: 1 }), i1.ɵqud(603979776, 3, { headerTpls: 1 }), i1.ɵqud(603979776, 4, { contentTpls: 1 }), (_l()(), i1.ɵand(0, null, null, 1, null, View_FaqComponent_5)), i1.ɵdid(9, 16384, [[4, 4]], 0, i3.NgbPanelContent, [i1.TemplateRef], null, null)], function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.question, ""); var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.question, ""); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = !i1.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
function View_FaqComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "container mb-5 p-4 border-0"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_4)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.faqs.length > 0); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.faqs; _ck(_v, 4, 0, currVal_1); }, null); }
function View_FaqComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.faqs.length > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FaqComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "section", [["class", "secondary mb-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h3", [["class", "page-heading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-2x text-primary align-middle fa-info-circle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0Ford Tube FAQ\u2019s"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FaqComponent_1)), i1.ɵdid(5, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.faqGroups; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_FaqComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-faq", [], null, null, null, View_FaqComponent_0, RenderType_FaqComponent)), i1.ɵdid(1, 114688, null, 0, i5.FaqComponent, [i6.FaqService, i7.UserService, i3.NgbAccordionConfig], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FaqComponentNgFactory = i1.ɵccf("app-faq", i5.FaqComponent, View_FaqComponent_Host_0, {}, {}, []);
export { FaqComponentNgFactory as FaqComponentNgFactory };
