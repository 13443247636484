import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/video.service";
export class VideoIdResolverService {
    constructor(videoService) {
        this.videoService = videoService;
    }
    resolve(route, state) {
        return this.videoService.getId(route.queryParams.v).pipe(response => response);
    }
}
VideoIdResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoIdResolverService_Factory() { return new VideoIdResolverService(i0.ɵɵinject(i1.VideoService)); }, token: VideoIdResolverService, providedIn: "root" });
