

/**
 * Enum for: FordTube.VBrick.Wrapper.Enums.VideoAccessControlType
 */
export enum VideoAccessControlType {

  Public = 0,
  Private = 1,
  AllUsers = 2,
}
 


