import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/events.service";
export class EventsListResolverService {
    constructor(eventsService) {
        this.eventsService = eventsService;
    }
    resolve(route, state) {
        return this.eventsService.list().pipe(response => response);
    }
}
EventsListResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EventsListResolverService_Factory() { return new EventsListResolverService(i0.ɵɵinject(i1.EventsService)); }, token: EventsListResolverService, providedIn: "root" });
