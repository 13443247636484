import * as i0 from "@angular/core";
import * as i1 from "../../../../domain/services/featured.service";
export class VideoFeaturedCategoryListResolverService {
    constructor(featuredService) {
        this.featuredService = featuredService;
    }
    resolve(route, state) {
        return this.featuredService.get().pipe(response => response);
    }
}
VideoFeaturedCategoryListResolverService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VideoFeaturedCategoryListResolverService_Factory() { return new VideoFeaturedCategoryListResolverService(i0.ɵɵinject(i1.FeaturedService)); }, token: VideoFeaturedCategoryListResolverService, providedIn: "root" });
