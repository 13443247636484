/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./signin-oidc.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./signin-oidc.component";
import * as i3 from "../auth.service";
import * as i4 from "@angular/router";
var styles_SigninOidcComponent = [i0.styles];
var RenderType_SigninOidcComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SigninOidcComponent, data: {} });
export { RenderType_SigninOidcComponent as RenderType_SigninOidcComponent };
export function View_SigninOidcComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Signing in..."]))], null, null); }
export function View_SigninOidcComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-signin-oidc", [], null, null, null, View_SigninOidcComponent_0, RenderType_SigninOidcComponent)), i1.ɵdid(1, 114688, null, 0, i2.SigninOidcComponent, [i3.AuthService, i4.ActivatedRoute, i4.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SigninOidcComponentNgFactory = i1.ɵccf("app-signin-oidc", i2.SigninOidcComponent, View_SigninOidcComponent_Host_0, {}, {}, []);
export { SigninOidcComponentNgFactory as SigninOidcComponentNgFactory };
